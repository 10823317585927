import React from "react"
import { FaCode, FaChartLine, FaWrench } from "react-icons/fa"
const services = [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "web development",
    text: `Front End web development using ReactJS and state management with Redux. I've also dabbled with VueJS and Angular.`,
  },
  {
    id: 2,
    icon: <FaChartLine className="service-icon" />,
    title: "Analytics & Tagging",
    text: `2.5 years experience in Digital Marketing, specializing in Analytics (Google Analytics 360) & Tagging (GTM).`,
  },
  {
    id: 3,
    icon: <FaWrench className="service-icon" />,
    title: "Bike Mechanic",
    text: `Volunteered as a bicycle mechanic at CERES`,
  },
]

export default services
