import React from "react"
import { Link } from "gatsby"
import SocialLinks from "../constants/social_links"
import {graphql, useStaticQuery } from "gatsby";
// import heroImg from "../assets/images/hero.svg"
import Image from "gatsby-image"
// import { StaticImage } from "gatsby-plugin-image"

const query = graphql`
{
  file(relativePath: {eq: "hero.png"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

const Hero = () => {
 const {
   file : {
     childImageSharp: { fluid }
   }
 } = useStaticQuery(query);
  
  return (
  <header className="hero">
    <div className="section-center hero-center">
      <article className="hero-info">
        <div>
          <div className="underline"></div>
            <h1>Hi i'm Jaz</h1>
            <h4>Web Developer</h4>
            <Link to="/contact" className="btn">
              contact me
            </Link>
            <SocialLinks />
        </div>
      </article>
      <Image fluid={fluid} className="hero-img" />
    </div>
  </header>
  )
}

export default Hero
