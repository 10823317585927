import React from "react"
import { graphql } from "gatsby"
import Hero from "../components/Hero"
import Services from "../components/Services"
import Layout from "../components/Layout"
import Jobs from "../components/Jobs"
import Projects from "../components/Projects"
import Seo from "../components/SEO"
import Blogs from "../components/Blogs"
import { Helmet } from "react-helmet"

const IndexPage = ({data}) => {
  const {
    allStrapiProjects: {nodes: projects},
    allStrapiBlogs: {nodes: blogs},
  } = data;


  return (
   <Layout>
     <Helmet>
       <meta name="google-site-verification" content="57kYuPdAsMP8E-mHfgspvI7Ed9xaMvkqqkdRluNncwM" />
     </Helmet>
     <Seo title="Home" description="This is the home page"></Seo>
     <Hero></Hero>
     <Services></Services>
     <Jobs></Jobs>
     <Projects projects={projects} title="featured projects" showLink></Projects>
     <Blogs blogs={blogs} title="latest blog" showLink />
   </Layout>
  )
}

export const query = graphql`
  {
    allStrapiProjects(filter: {featured: {eq: true}}) {
      nodes {
        github
        id
        description
        title
        url
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        stack {
          id
          title
        }
      }
    },
    allStrapiBlogs(sort: {order: ASC, fields: date}, limit: 3) {
      nodes {
        slug
        date(formatString: "MMMM D YYYY")
        id
        title
        category
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default IndexPage
